import MainContent from "./MainContent";
import Sidebar from "./Slidebar";

function Content(){
    return (
        <div class='content'>
            <Sidebar />
            <MainContent />
        </div>
    );
}
export default Content;